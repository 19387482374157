<template>
  <v-container
    fluid
    style="display:flex; flex-direction: column; height: 100%;"
  >
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; left: 0; right: 0; background: #DBC7FF"
      :title="'Back'"
    />
    <div
      class="mb-3 d-flex flex-row mx-15"
      style="margin-top: 40px; height: 100%;overflow-x: hidden; overflow-y:auto;"
    >
      <div
        style="width: 300px; display: flex; flex-direction: column; align-items: center; margin-top: 50px;"
        class="hidden-xs-only"
      >
        <editable-avatar
          class="mb-5"
          :image="profile.image"
          :onImageSelected="onImageSelected"
        />
        <label class="name-title">
          {{
            (profile.first_name ? profile.first_name : "") +
              " " +
              (profile.last_name ? profile.last_name : "")
          }}
        </label>
        <label class="email-title">
          {{ profile.email }}
        </label>
        <GmapMap :center="center" :zoom="15" class="map mt-4" v-if="center">
          <GmapMarker :position="center" />
        </GmapMap>
      </div>
      <div style="background-color: lightgrey; width: 1px" />
      <v-layout style="display: flex; flex-direction: column;">
        <label
          class="item-title"
          v-text="
            $route.params.id != 'add' ? 'Partner Details' : 'Create a Partner'
          "
        />
        <label class="email-title ml-10 mb-5"
          >Update and verify the partner information</label
        >
        <v-row class="px-6">
          <v-col class="px-3" md="6" cols="12">
            <v-text-field
              v-model="profile.first_name"
              label="First Name"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-text-field
              v-model="profile.last_name"
              label="Last Name"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-text-field
              v-model="profile.email"
              label="Email Address"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
            />
            <v-text-field
              v-model="profile.phone"
              label="Phone"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-text-field
              v-model="profile.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.min]"
              @click:append="showPassword = !showPassword"
              @change="onChange"
              label="Password"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              class="pb-0 mb-0 mt-3"
              autocomplete="off"
            />
            <vuetify-google-autocomplete
              id="map"
              placeholder=""
              label="Address"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              v-model="profile.address"
              v-on:placechanged="getAddressData"
              class="mt-3 autocomplete"
              ref="address"
            >
            </vuetify-google-autocomplete>
            <v-text-field
              v-model="profile.address2"
              label="Address 2"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
          </v-col>
          <v-col class="px-3 mt-3" md="6" cols="12">
            <v-select
              v-model="profile.type"
              label="Type"
              :items="typeOptions"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
            />
            <v-text-field
              v-model="profile.website"
              label="Website"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
            />
            <v-text-field
              v-model="profile.companyInfo"
              label="Company Name"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-select
              v-model="profile.service"
              label="Service Type"
              :items="serviceTypes"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
            <v-text-field
              v-model="profile.balance"
              label="Balance"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
              v-if="profile._id && profile.service == 'Food'"
            />
            <v-select
              v-model="profile.status"
              label="Status"
              :items="statusItems"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
            />
          </v-col>
        </v-row>
        <div
          style="display:flex; justify-content:flex-end; flex-direction: row;"
        >
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="onDeletePartner()"
            :loading="loading"
            v-if="profile._id"
          >
            Delete
          </v-btn>
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="onSaveDetails()"
            :loading="loading"
          >
            Save
          </v-btn>
        </div>
      </v-layout>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <confirm-delete-dialog
      :deleteDialog="deletePartnerDialg"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
  </v-container>
</template>
<script>
import Menu from "@/components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

export default {
  components: {
    Menu,
    EditableAvatar,
    ConfirmDeleteDialog,
    VuetifyGoogleAutocomplete,
  },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      showPassword: false,
      typeOptions: ["Freezer", "Partner"],
      serviceTypes: [],
      statusItems: ["Available", "Pending", "Disabled"],
      selectedImage: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      profile: {},
      deletePartnerDialg: false,
      center: null,
    };
  },
  methods: {
    ...mapActions({
      getPartner: "partner/getPartner",
      editPartner: "partner/editPartner",
      deletePartner: "partner/deletePartner",
      setBalance: "partner/setBalance",
      getValues: "variable/getValues",
      onLogout: "auth/onLogout",
    }),
    onSaveDetails() {
      this.loading = true;
      var params = {
        type: this.profile.service,
        user_id: this.profile._id,
        amount: this.profile.balance,
      };
      if (
        this.profile.balance &&
        this.profile._id &&
        this.profile.service == "Food"
      ) {
        this.setBalance(params);
      }

      var formData = new FormData();
      Object.keys(this.profile).map((key) => {
        if (this.profile[key]) formData.append(key, this.profile[key]);
      });
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.editPartner(formData)
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    getAddressData(addressData) {
      this.profile.address = addressData.name;
      this.profile.state = addressData.administrative_area_level_1;
      this.profile.city = addressData.locality;
      this.profile.zip = addressData.postal_code;
      this.profile.location =
        addressData.latitude + "," + addressData.longitude;
      this.$refs.address.update(this.profile.address);
      this.center = { lat: addressData.latitude, lng: addressData.longitude };
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    onDeletePartner() {
      this.deletePartnerDialg = true;
    },
    onCloseConfirmDelete() {
      this.deletePartnerDialg = false;
    },
    onConfirmDelete() {
      this.deletePartnerDialg = false;
      this.loading = true;
      this.deletePartner({ _id: this.profile._id })
        .then(() => {
          this.loading = false;
          this.onLogout();
          this.$router.back();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      myProfile: "auth/getProfile",
    }),
  },
  mounted() {
    if (this.myProfile) {
      this.profile = this.myProfile;
    }
    this.getValues({ title: "Partner Types" })
      .then((res) => {
        this.serviceTypes = res.map((v) => v.text);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    "profile.location": function(newValue) {
      if (newValue.length == 2) {
        this.center = {
          lat: parseFloat(newValue[0]),
          lng: parseFloat(newValue[1]),
        };
      }
    },
    myProfile(newValue) {
      this.profile = newValue;
    },
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.map {
  width: 200px;
  height: 150px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
